import React from 'react';

const Introduction = () => {
	return (
		<section className='section is-medium is-primary has-text-centered is-long'>
			<div className='container'>
				<div className='columns is-centered'>
					<div className='column is-three-fifths'>
						<h1 className='title is-spaced is-size-3-desktop is-size-4-mobile'>
							Hi, I’m Ivan. Nice to meet you.
						</h1>
						<h2 className='subtitle is-size-5-desktop'>
						Although correct self description is nearly impossible for anyone to achieve, I will point out some characteristics of mine other people have noticed: insightful, driven, honest and caring. I am a 26-year-old entrepreneur who specializes in Full-Stack Web Development. I am currently open for hiring. Thank you for checking out my website!

						</h2>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Introduction;
